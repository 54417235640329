/**
 * Site header
 */
.site-header {
  border-top: 5px solid $grey-color;
  border-bottom: 1px solid $grey-color-light;

  @include media-query($on-laptop) {
    min-height: 100px;
  }

  // Positioning context for the mobile navigation icon
  position: relative;

  .site-header-float {
    min-height: 60px;
  }

  .lang-selector {
    position: absolute;
    z-index: 900;
    top: 0px;
    right: 0px;
    width: 30px;
    text-align: center;
    margin-right: 10px;
    padding: 10px;

    .lang-intro {
      display: none;
    }

    .lang {
      font-variant: small-caps;
      text-transform: capitalize;
      list-style: none;
      line-height: 40px;

      &:nth-child(n+3) {
        display: none;
        padding: 10px 0 10px 0;
      }

      &:nth-child(2) {
        padding-bottom: 10px;
      }

      a {
        color: $grey-color-light;
      }

    }

    &:hover {
      padding-bottom: 0px;
      background: $grey-color;

      .lang:first-child {
        padding-bottom: 10px;
      }

      .lang a {
        color: $brand-color;

        &:hover {
          text-decoration: none;
          color: $grey-color-light;
        }
      }

      .lang:nth-child(n+3) {
        border-top: 1px solid $grey-color-light;
        display: block;
      }
    }

    @include media-query($on-laptop) {
      position: relative;
      left: 0;
      width: auto;
      clear: both;
      margin: 0 -$spacing-unit/2 0 -$spacing-unit/2;
      padding: 5px 5px 5px $spacing-unit/2;
      background: $grey-color-lighter;
      text-align: left;
      @include size(small);

      .lang-intro {
        display: inline-block;
      }

      .lang:nth-child(n+1) {
        padding: 5px 5px 5px 0;
        line-height: normal;
        display: inline-block;

        a {
          color: $grey-color-dark;

          &:hover {
            text-decoration: underline
          }
        }
      }

      .lang:nth-child(n+3):before {
        content: "| ";
      }

      &:hover {
        background: $grey-color-lighter;
        padding: 5px 5px 5px $spacing-unit/2;

        .lang:nth-child(n+1) {
          padding: 5px 5px 5px 0;
          display: inline-block;
          border: none;

          a { color: $grey-color-dark; }
        }
      }

      @include media-query($on-palm) {
        margin-top: 0px;
      }
    }
  }
}

.site-title {
  display: inline-block;
  font-size: 26px;
  font-weight: 300;
  line-height: 55px;
  letter-spacing: -1px;
  margin-bottom: -5px;

  &,
  &:visited {
    color: $grey-color-dark;
  }
  &:hover {
    text-decoration: none;
    border-bottom: 5px $brand-color solid;
  }

  b {
    font-weight: 600;
  }
}

.with-pitch {
  &:hover {
    border-bottom: none;
    color: $brand-color;
  }
}

.site-pitch {
  display: inline-block;
  clear: both;
  line-height: 60px;
  padding-left: 10px;
  color: $grey-color;

  &:before {
    content: " | "
  }

  @include media-query($on-palm) {
    display: block;
    padding-left: 0;
    padding-bottom: 15px;
    line-height: initial;
    color: $grey-color-light;

    &:before {
      content: ""
    }
  }
}


.site-nav {
  float: right;
  line-height: 60px;
  z-index: 1000;

  .menu-icon {
    display: none;
  }

  .nav-list-title {
    display: none;
  }


  .nav-list {
    list-style: none;
    margin: 0;

    li {
      display: inline-block;
      height: 55px;

      .page-link {
        color: $text-color;

        &:hover {
          text-decoration: none;
        }
      }

      // Gaps between nav items, but not on the last one
      &:not(:last-child) {
        margin-right: 15px;
      }

      &:hover {
        border-bottom: 5px $grey-color solid;
      }

      @include media-query($on-palm) {
        height: auto;
        line-height: normal;

        &:nth-child(n+0) {
          margin: 0;
          height: auto;
          padding: 0 10px 0px;
        }

        a {
          margin: 0px;
          padding: 0px;
        }

      }

    }
  }

  @include media-query($on-palm) {
    position: absolute;
    top: 9px;
    right: $spacing-unit / 2;
    background-color: $background-color;
    border: 1px solid $grey-color-light;
    border-radius: 5px;
    text-align: right;
    margin-left: 20px;

    .menu-icon {
      display: block;
      float: right;
      width: 36px;
      height: 26px;
      line-height: 0;
      padding-top: 10px;
      text-align: center;

      > svg path {
        fill: $grey-color-dark;
      }
    }

    .trigger {
      clear: both;
      display: none;
    }

    &:hover .trigger {
      display: block;
      padding-bottom: 5px;
    }

    .nav-list {
      .page-link:hover {
        color: $brand-color;
      }

      li:hover {
        border: none;
      }
    }

    .page-link {
      display: block;
      padding: 5px 10px;

      &:not(:last-child) {
        margin-right: 0;
      }
      margin-left: 20px;
    }
  }
}

/**
 * Site footer
 */

.site-footer {
  @include size(vsmall);
  border-top: 1px solid $grey-color-light;
  color: $grey-color;

  a {
    color: $grey-color;
  }

  .footer-col {
    list-style: none;
    margin-left: 0;
    margin-top: $spacing-unit/2;
    margin-bottom: $spacing-unit;

    .author, .author a {
      color: $grey-color-light;
    }
  }

  .footer-col-1 {
    width: -webkit-calc(65% - (#{$spacing-unit} / 2));
    width:         calc(65% - (#{$spacing-unit} / 2));
    float: left;
  }

  .footer-col-2 {
    width: -webkit-calc(35% - (#{$spacing-unit} / 2));
    width:         calc(35% - (#{$spacing-unit} / 2));
    float: right;
  }

  .single-col {
    clear: both;
    list-style: none;
    margin: 0;
    text-align: center;

    li {
      display: inline-block;
      margin-left: 5px;
      margin-right: 5px;
    }

    .username {
      display: none;
    }

    svg {
      width: 20px;
      height: 20px;
    }

    .icon {
      &:hover {
        circle,
        path:last-of-type {
          fill: $text-color;
        }
      }
    }
  }

  .nav-list-title,
  .contact-list-title {
    display: none;
  }

  .contact-list {
    a .username:hover {
      text-decoration: underline;
    }

    @include media-query($on-palm) {
      margin-bottom: $spacing-unit / 2;
    }
  }

  .bottom {
    color: $grey-color-light;
    width: 100%;
    clear: both;
    float: none;
    text-align: center;
    margin-top: $spacing-unit;
    margin-bottom: $spacing-unit;

    @include media-query($on-palm) {
      margin-top: 0px;
    }

    a {
      color: $grey-color-light;
    }

    .cc-licence {
      text-transform: uppercase;
    }

    .lang-selector {
      display: inline-block;
      list-style: none;
      color: $grey-color;
      margin: $spacing-unit / 4 0 0 0;
      padding: 10px;

      @include media-query($on-palm) {
        text-align: left;
        padding-left: 0px;
        margin: 0px 0px $spacing-unit/2 0px;

        .lang-intro {
          font-weight: bold;
        }
      }

      .lang {
        display: inline-block;
        text-transform: lowercase;

        a {
          color: $grey-color;
        }

        &:nth-child(n+3) {
          &:before {
            content:"|"
          }
        }
      }
    }
  }

}

@include media-query($on-laptop) {
  .site-footer {
    @include size(small);
  }
}

@include media-query($on-palm) {
  .site-footer {
    .footer-col-1,
    .footer-col-2 {
      width:100%;
      float: none;
    }

    .nav-list-title,
    .contact-list-title {
      display: inline;
      font-weight: bold;
    }

    .nav-list {
      margin-top: 0;
    }

    .contact-list {
      text-align: left;
      margin-top: 0;

      li {
        display: block;
      }

      .username {
        display: inline;
      }
    }

    .bottom {
      text-align: left;
    }
  }

}

/**
 * Page content
 */
.page-content {
  padding: $spacing-unit 0 $spacing-unit / 2 0;
}

.post-content {
  padding-bottom: $spacing-unit;
}

.page-heading {
  margin-top: 0;
}

.pitch {
  h2:first-of-type {
    margin-top: 0;
  }
}

.archive {
  margin-top: $spacing-unit;
}

.post-list {
  margin-left: 0;
  list-style: none;

  .last-update {
    display: none;
    color: $grey-color-light;
    a {
      color: $grey-color-light;
    }
  }

  > li {
    margin-bottom: $spacing-unit;

    &:hover .last-update {
      display: inline-block;
    }
  }

  .post-link {
    &:hover {
      text-decoration: none;
    }
  }

  .post-meta {
    font-size: $small-font-size;
    font-family: $alt-font-family;
    font-weight: 200;
    color: $grey-color;
  }

  .post-thumbnail-wrapper {
    width: 100%;
    max-height: 200px;
    overflow-y: hidden;
    margin-top: $spacing-unit / 4;
    margin-bottom: $spacing-unit / 4;
  }

  .post-thumbnail {
    margin-top: -20%;
  }
}

/**
 * Posts
 */

.post-header {
  position: relative;
  width: 100vw;
  left: calc(-50vw + 50%);
  margin-bottom: $spacing-unit;
  overflow-y: hidden;

  @include media-query($on-laptop) {
    .post-meta {
      margin-bottom: $spacing-unit / 6;
    }
  }

  .last-update {
    color: $grey-color;
    a { color: inherit }
  }

  .post-thumbnail {
    position: absolute;
    z-index: -1;
    min-height: 100%;

    @include media-query($on-palm) {
      position: relative;
      width: 100%;
    }
  }

  .wrapper {
    background: rgba(255, 255, 255, 0.9);
    margin-top: $spacing-unit;
    padding-bottom: 0;
    padding-top: 0;

    @include media-query($on-laptop) {
      margin-top: $spacing-unit / 1.5;
    }

  }
}

.with-thumbnail {
  @include media-query($on-laptop) {
    margin-bottom: $spacing-unit / 2;
  }

  @include media-query($on-palm) {
    margin-bottom: 0;
  }

  .wrapper {
    padding-bottom: $spacing-unit / 2;
    padding-top: $spacing-unit / 2;
    margin-bottom: $spacing-unit;

    @include media-query($on-palm) {
      margin: 0px;
    }
  }
}

.post-title {
  margin-top: 0;
  color: $brand-color;
}

.post-summary {
  margin-top: 0;
}

.post-content {
  padding-bottom: $spacing-unit / 2;

  .footnotes {
    @include size(small);
    margin-top: $spacing-unit / 6;
    padding-top: $spacing-unit / 2;

    @include media-query($on-laptop) {
      p {
        margin-bottom: $spacing-unit / 4;
      }
    }

    @include media-query($on-palm) {
      margin-top: 0;
    }

    hr {
      margin-top: 0;
    }
  }
}

.post-author {
  @include size(vsmall);
  padding: 5%;
  margin-top: $spacing-unit / 2;

  .user-picture {
    float: left;
    width: 20%;
    margin-right: 5%;
  }

  .user-info {
    width: 75%;
    list-style: none;
  }

  .user-shortbio {
    color: grey;
  }
}

.colored-block {
  @include size(small);
  background: $grey-color-lighter;
  padding: 5%;
}

/**
 * About
 */

.about-col-wrapper {
  @extend %clearfix;
}

.about-col {
  float: left;

  @include media-query($on-palm) {
    float: none;

    .contact-list {
      text-align: center;

      &:last-of-type {
        margin-bottom: $spacing-unit / 2;
      }
    }
  }

}

.contact-list-title {
  display: none;
}

.contact-list {
  list-style: none;
  margin-left: 0;
  margin-top: $spacing-unit / 2;
  text-transform: lowercase;

  a:hover {
    text-decoration: none;
  }
}

.user-picture {
  border-radius: 50%;
  max-width: calc(85%);
}

.about-col-1 {
  @include size(vsmall);
  padding-right: $spacing-unit / 2;
  width: -webkit-calc(35% - (#{$spacing-unit} / 4));
  width:         calc(35% - (#{$spacing-unit} / 4));


  .user-picture {
    margin-bottom: $spacing-unit / 2;
  }

}

.about-col-2 {
  @include size(small);
  width: -webkit-calc(65% - (#{$spacing-unit} / 4));
  width:         calc(65% - (#{$spacing-unit} / 4));

  h1 {
    margin-top: 0;
  }
}

@include media-query($on-laptop) {
  .about-col {
    @include size(p);
  }
}

@include media-query($on-palm) {
  .about-col {
    @include size(p);
    padding: 0;
    float: none;
    width: auto;
  }

  .about-col-1 {
    padding: 5%;
    max-width: 200px;
    margin: auto;
  }

  .contact-list {
    margin: 0;
  }

  .user-picture {
    max-width: 200px;
  }
}

.msg-404 {
  margin-bottom: $spacing-unit;

  .title {
    @include size(h1);
    font-family: $alt-font-family;
    color: $brand-color;
  }

  .description {
    @include size(h2);
    font-family: $base-font-family;
  }
}

.no-version {
  cursor: default;
}

.invisible {
  display: none;
}
