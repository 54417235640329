// Define defaults for each variable.

$type-scale-large: (
  h1: 2.941rem,
  h2: 2.111rem,
  h3: 1.663rem,
  h4: 1.333rem,
  p: 1.25rem,
  small: 1.15rem,
  vsmall: 1rem,
);

$type-scale-medium: (
  h1: 2.441rem,
  h2: 1.653em,
  h3: 1.563rem,
  h4: 1.333rem,
  p: 1.25rem,
  small: 1.15rem,
  vsmall: 1rem,
);

$type-scale-small: (
  h1: 2.148rem,
  h2: 1.719rem,
  h3: 1.375rem,
  h4: 1.233rem,
  p: 1.1rem,
  small: 0.95rem,
  vsmall: 0.85rem,
);

$type-line-large: (
  h1: map-get($type-scale-large, h1)*1.1,
  h2: map-get($type-scale-large, h2)*1.2,
  h3: map-get($type-scale-large, h3)*1.2,
  h4: map-get($type-scale-large, h4)*1.2,
  p: map-get($type-scale-large, p)*1.6,
  small: map-get($type-scale-large, small)*1.6,
  vsmall: map-get($type-scale-large, vsmall)*1.6,
);

$type-line-medium: (
  h1: map-get($type-scale-medium, h1)*1.1,
  h2: map-get($type-scale-medium, h2)*0.8,
  h3: map-get($type-scale-medium, h3)*1.3,
  h4: map-get($type-scale-medium, h4)*1.3,
  p: map-get($type-scale-medium, p)*1.6,
  small: map-get($type-scale-medium, small)*1.6,
  vsmall: map-get($type-scale-medium, vsmall)*1.6,
);

$type-line-small: (
  h1: map-get($type-scale-small, h1)*1.1,
  h2: map-get($type-scale-small, h2),
  h3: map-get($type-scale-small, h3)*0.9,
  h4: map-get($type-scale-small, h4)*0.9,
  p: map-get($type-scale-small, p)*1.6,
  small: map-get($type-scale-small, small)*1.6,
  vsmall: map-get($type-scale-small, vsmall)*1.6,
);


$breakpoint-medium: 75em;
$breakpoint-small: 45em;

@mixin size($level) {
  font-size: map-get($type-scale-large, $level);
  line-height: map-get($type-line-large, $level);

  @media (max-width: $breakpoint-medium) {
    font-size: map-get($type-scale-medium, $level);
    line-height: map-get($type-line-medium, $level);
  }
  @media (max-width: $breakpoint-small) {
    font-size: map-get($type-scale-small, $level);
    line-height: map-get($type-line-medium, $level);
  }
}

$base-font-family: "PT Serif", serif !default;
$base-font-size:   16px !default;
$base-font-weight: 400 !default;
$small-font-size:  $base-font-size * 0.875 !default;

$alt-font-family: "Open Sans", sans-serif !default;
$alt-font-weight: bold !default;

$spacing-unit:     50px !default;

$text-color:       #111 !default;
$background-color: #fdfdfd !default;
$brand-color:      #d33682 !default;

$grey-color:       #3F3F3F !default;
$grey-color-light: lighten($grey-color, 50%) !default;
$grey-color-lighter: lighten($grey-color, 90%) !default;
$grey-color-dark:  darken($grey-color, 25%) !default;

// Width of the content area
$content-width:    700px;

$on-palm:          600px !default;
$on-laptop:        800px !default;

// Use media queries like this:
// @include media-query($on-palm) {
//   .wrapper {
//     padding-right: $spacing-unit / 2;
//     padding-left: $spacing-unit / 2;
//   }
// }
@mixin media-query($device) {
  @media screen and (max-width: $device) {
    @content;
  }
}

// Import partials.
@import
  "academic/base",
  "academic/layout",
  "academic/syntax-highlighting"
;
